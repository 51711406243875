// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-galerie-index-js": () => import("./../../src/pages/galerie/index.js" /* webpackChunkName: "component---src-pages-galerie-index-js" */),
  "component---src-pages-impressum-index-js": () => import("./../../src/pages/impressum/index.js" /* webpackChunkName: "component---src-pages-impressum-index-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-sortiment-index-js": () => import("./../../src/pages/sortiment/index.js" /* webpackChunkName: "component---src-pages-sortiment-index-js" */)
}

